/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=PT+Sans&display=swap');

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
